.apple-pay-btn-container {
  @apply bg-black rounded-button h-14 flex flex-col justify-center;

  & > button {
    @apply w-full rounded-button h-[40px];
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
    -apple-pay-button-style: black;
  }
}
