@import 'fonts';
@import 'themes';
@import 'tailwind';
@import 'components/applepay';
@import 'components/braintree';
@import 'components/tooltip';

@layer base {
  * {
    @apply focus:outline-none focus-visible:ring focus-visible:ring-focus focus-visible:ring-offset-2;
  }

  html,
  body {
    @apply bg-body font-app text-gray text-base;
  }
}

@layer components {
  .focused-container {
    @apply focus-within:outline-none focus-within:ring focus-within:ring-focus;
  }

  .disabled {
    @apply opacity-50;
  }

  .group:hover .group-hover\:flex {
    display: flex;
  }

  .tooltip {
    @apply invisible absolute;
  }

  .has-tooltip {
    @apply relative;

    &:hover .tooltip {
      @apply visible z-50;
    }
  }

  .toasterprogress {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    transform-origin: left;
    animation: Toastify__trackProgress linear 1 forwards;
  }

  .input-field {
    @apply rounded-field border-2 border-transparent h-14 px-4 text-sm outline-none caret-primary bg-secondary;

    &.error {
      @apply caret-error border-error bg-white;
    }

    &.focus {
      @apply caret-primary border-focus bg-white;
    }
  }
}
