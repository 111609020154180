#result {
  margin-top: 1rem;
  background-color: #3a4453;
  color: #fff;
  font-size: 12px;
}

.item {
  /* flex: 0 48%; */
  height: 100%;
  margin-bottom: 2%; /* (100-32*3)/2 */
  padding-right: 15px;
}
.item100 {
  flex: 0 100%;
  height: 50px;
  margin-top: 0%;
  margin-bottom: 2%; /* (100-32*3)/2 */
  justify-content: center;
}

.toggle-content,
.is-hide {
  display: none;
}

.toggle-content.is-visible {
  display: block;
}

.webSDK-placeholder {
  height: 20rem;
}

.pay-button {
  background-color: #00becf;
  border-radius: 3px;
  border: 1px solid #00a6b5;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 140px;
}
.pay-button:hover {
  color: #fff;
  background-color: #0f93a1;
}
.validation-error {
  color: #ff3f5a;
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  visibility: hidden;
}

#card_number {
  width: 700px;
}
#cardholder_name {
  width: 470px;
}

.sfcModal-header {
  height: 1.5rem;
}
.sfcModal-dialog {
  margin: 55px auto;
  max-width: 492px;
  position: relative;
  width: auto;
}
.sfcModal-content {
  background-clip: padding-box;
  background-color: #ffffff;
  border: 1px solid #dfdfdf;
  outline: 0;
  position: relative;
}
.sfcModal-close {
  border: 0;
  color: #2c2a2a;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
}
.sfcIcon--close:before {
  content: '\2716';
}
