.tooltip {
  @apply relative inline-block visible;
}

.tooltip .tooltiptext {
  @apply invisible opacity-0 bg-white text-gray p-sm rounded-panel text-center;
  @apply absolute z-10 border border-primary bottom-8 -right-4;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  @apply absolute content-[""] border-8 border-transparent border-t-primary right-4 top-full;
}

.tooltip:hover .tooltiptext {
  @apply visible opacity-100;
}
