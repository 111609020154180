:root {
  // Generic gray-ish theme
  --primary: #495057;
  --primaryInteraction: #41484e;
  --secondary: #e9ecef;
  --disabled: rgba(233, 236, 239, 0.5); // 50% --secondary
  --componentBg: white;
  --bodyBg: var(--secondary);
  --appFontFamily: 'uni-neue', sans-serif;
  --appHeadingFontFamily: 'uni-neue', sans-serif;
  --borderButton: 0;
  --borderField: 0;
  --borderFieldColor: #a7a9ac;
  --borderFieldFocusColor: var(--primary);
  --borderPanel: 0;
  --orderText: white;

  // Font-weight
  --lightFont: 300;
  --normalFont: 400;
  --boldFont: 600;
  --headingFontWeight: var(--boldFont);

  // Colors
  --success: #0f9a65;
  --info: #1d4582;
  --warn: #bf6203;
  --error: #cd0039;
  --gray: #090909;
  --silver: #a7a9ac;
}

[data-theme='pm'] {
  --primary: #004b8d;
  --primaryInteraction: #00437e;
  --secondary: #e5f1fb;
  --disabled: rgba(229, 241, 251, 0.5); // 50% --secondary
  --bodyBg: #f5f5f5;
  --componentBg: white;
  --appFontFamily: 'uni-neue', sans-serif;
  --appHeadingFontFamily: 'uni-neue', sans-serif;
  --borderButton: 5rem;
  --borderField: 0px;
  --borderFieldColor: #121b22;
  --borderFieldFocusColor: var(--primary);
  --borderPanel: 0;
  --orderText: white;
  --gray: #061423;
  --headingFontWeight: var(--normalFont);
}

[data-theme='fitline'] {
  --primary: #cd0039;
  --primaryInteraction: #b80033;
  --secondary: #fff3e0;
  --disabled: rgba(255, 243, 224, 0.5); // 50% --secondary
  --bodyBg: var(--secondary);
  --componentBg: white;
  --appFontFamily: Helvetica, sans-serif;
  --appHeadingFontFamily: 'clan-pro', sans-serif;
  --borderButton: 5rem;
  --borderField: 8px;
  --borderFieldColor: #f6d7a4;
  --borderFieldFocusColor: #f6d7a4;
  --borderPanel: 8px;
  --orderText: white;
  --gray: #090909;
  --headingFontWeight: var(--boldFont);
}
